import React, { useEffect, useRef, useState } from 'react'
import Select from 'react-select';
import { colourStyles } from '../../utils/customSelectStyle';
import { eventDuration, eventTime } from '../../utils/constant';
import '../../assets/css/jquery-steps.css'
import { Link } from 'react-router-dom';
import { createOrganizerEvent, getAllCategories, getAllCountries, getAllTicketBySlug, updateEvent } from '../../api';
import { useSelector } from 'react-redux';
import Modal from '@mui/material/Modal';

import { RootState } from '../../store/rootReducer';
import { images } from '../../assets/images';
import TicketCard from '../../components/common/Ticket/TicketCard';
import EventBasicTab from './EventBasicTab';
import TicketTab from './TicketTab';
import AddNewTicketModal from './AddNewTicketModal';
import { TicketsType } from '../../types/Events';
type CreateVenueEventProps = {}

const steps = [
    'Details',
    'Tickets',
    'Settings',
];

const EditEvent = (props: CreateVenueEventProps) => {
    const { organizers, loading, error } = useSelector((state: RootState) => state.organizer);
    const fileInputRef = useRef<HTMLInputElement>(null);

    const [activeStep, setActiveStep] = React.useState(0);

    const [categories, setCategories] = useState([])
    const [countries, setCountries] = useState([])
    const [selectedFile, setSelectedFile] = useState(null);
    const [eventId, setEventId] = useState(null)
    const [tickets, setTickets] = useState([])
    const [eventSlug, setEventSlug] = useState('new-event')
    const [ticketModalOpen, setTicketModalOpen] = useState(false)
    const [selectedTicketForEdit, setSelectedTicketForEdit] = useState<TicketsType>()


    const [formData, setFormData] = useState({
        eventName: '',
        eventDate: '',
        eventTime: '',
        eventDuration: '',
        eventBanner: '',
        eventDescription: '',
        eventVenue: '',
        eventAddress1: '',
        eventAddress2: '',
        eventCountry: 0,
        eventState: '',
        eventCity: '',
        eventZip: '',
        categoryId: 0,
        ticketType:'',
        eventType:'',
        onlineEventType:'',
        eventStatus: 'active',

    });

    useEffect(() => {
        const fetchCategory = async () => {
            const res = await getAllCategories();
            const categoryOption = res.map((category: any) => ({
                value: category.categoryId,
                label: category.categoryName
            }));
            setCategories(categoryOption)
        }
        const fetchCountry = async () => {
            const res = await getAllCountries();
            const countryOption = res.map((country: any) => ({
                value: country.countryId,
                label: country.countryName
            }));
            setCountries(countryOption)
        }
        fetchCategory();
        fetchCountry();
        getTickets(eventSlug);
    }, [])

    const getTickets = async (slugId:string) => {
        const response = await getAllTicketBySlug(slugId)
        setTickets(response)
    }
    const totalSteps = () => {
        return steps.length;
    };


    const isLastStep = () => {
        return activeStep === totalSteps() - 1;
    };

    const allStepsCompleted = () => {
        return activeStep === 2;
    };
    const handleNext = async () => {
        const newActiveStep =
            isLastStep() && !allStepsCompleted()
                ? // It's the last step, but not all steps have been completed,
                // find the first step that has been completed
                2
                : activeStep + 1;
        if (eventId === null) {
            try {
                const resp = await createOrganizerEvent(formData);
                setEventId(resp.eventId)
                setEventSlug(resp.slugId)
                setActiveStep(newActiveStep);
                getTickets(resp.slugId)
            } catch (e) {
                console.log("🚀 ~ handleNext ~ e", e)
            }
        }else{
            try {
                const resp = await updateEvent(eventId,formData);
                setActiveStep(newActiveStep);
                getTickets(eventSlug)
            } catch (e) {
                console.log("🚀 ~ handleNext ~ e", e)
            }
        }



    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };



    const handleChange = (e: { target: { name: any; value: any; }; }) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value,
        }));
    };
    const onSelectChange = (data: any, key: string) => {
        setFormData(prevState => ({
            ...prevState,
            [key]: data.value,
        }));

    }
    const handleThumbBtnClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };
    const handleFileChange = (event: any) => {
        if (event.target.files) {
            setSelectedFile(event.target.files[0] || null);
            setFormData(prevState => ({
                ...prevState,
                eventBanner: event.target.files[0] || null,
            }));

        }
    };
    const handleCloseTicketModal = () => {
        setTicketModalOpen(false)
    }
    const onEditTicket = (ticket:TicketsType ) => {
        setSelectedTicketForEdit(ticket)


    }
    const chooseOnlineEventTypes = (eventType:string) => {
        setFormData(prevState => ({
            ...prevState,
            onlineEventType: eventType,
        }));
    }
    const renderStepButton = () => {
        switch (activeStep) {
            case 0:
                return (
                    <button className="btn btn-default btn-hover steps_btn" onClick={handleNext}>Next</button>
                )
            case 1:
                return (
                    <>
                        <button className="btn btn-default btn-hover steps_btn" onClick={handleBack}>Previous</button>
                        <button className="btn btn-default btn-hover steps_btn" onClick={handleNext}>Next</button>
                    </>
                )
            case 2:
                return (
                    <>
                        <button className="btn btn-default btn-hover steps_btn" onClick={handleBack}>Previous</button>
                        <button className="btn btn-default btn-hover steps_btn">Create</button>
                    </>
                )
        }
    }
    return (
        <div className='col-md-12'>
            <div className="event-dt-block p-80">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-12 col-md-12">
                            <div className="main-title text-center">
                                <h3>Create Online Event</h3>
                            </div>
                        </div>
                        <div className="col-xl-8 col-lg-9 col-md-12">
                            <div className="wizard-steps-block">
                                <div id="add-event-tab" className="step-app">
                                    {/* <Stepper activeStep={activeStep} alternativeLabel className='step-steps'>
                                        {steps.map((label) => (
                                            <Step className="number" key={label}>
                                                <StepLabel className="step-name">{label}</StepLabel>
                                            </Step>
                                        ))}
                                    </Stepper> */}
                                    <ul className="step-steps">
                                        <li className={activeStep === 0 ? "active" : activeStep === 1 || 2 ? 'done' : ''}>
                                            <Link to={"#"}>
                                                <span className="number"></span>
                                                <span className="step-name">Details</span>
                                            </Link>
                                        </li>
                                        <li className={activeStep === 1 ? "active" : activeStep === 2 ? 'done' : ''}>
                                            <Link to={"#"}>
                                                <span className="number"></span>
                                                <span className="step-name">Tickets</span>
                                            </Link>
                                        </li>
                                        <li className={activeStep === 2 ? "active" : ''}>
                                            <Link to={"#"}>
                                                <span className="number"></span>
                                                <span className="step-name">Setting</span>
                                            </Link>
                                        </li>
                                    </ul>
                                    <div className="step-content">
                                        {activeStep === 0 &&
                                           <EventBasicTab
                                             formData={formData}
                                                handleChange={handleChange}
                                                onSelectChange={onSelectChange}
                                                handleThumbBtnClick={handleThumbBtnClick}
                                                handleFileChange={handleFileChange}
                                                selectedFile={selectedFile}
                                                categories={categories}
                                                countries={countries}
                                                fileInputRef={fileInputRef}
                                                chooseOnlineEventTypes={chooseOnlineEventTypes}
                                            />
                                        }
                                        {activeStep === 1 &&
                                            <TicketTab
                                                formData={formData}
                                                tickets={tickets}
                                                setTicketModalOpen={setTicketModalOpen}
                                                onEditTicket={onEditTicket}
                                               
                                            />
                                        }
                                        {activeStep === 2 &&
                                            <div className="step-tab-panel step-tab-location" id="tab_step3">
                                                <div className="tab-from-content">
                                                    <div className="main-card">
                                                        <div className="bp-title">
                                                            <h4><i className="fa-solid fa-gear step_icon me-3"></i>Setting</h4>
                                                        </div>
                                                        <div className="p_30 bp-form main-form">
                                                            <div className="form-group">
                                                                <div className="ticket-section">
                                                                    <label className="form-label fs-16">Let's configure a few additional options for your event!</label>
                                                                    <p className="mt-2 fs-14 d-block mb-3 pe_right">Change the following settings based on your preferences to customise your event accordingly.</p>
                                                                    <div className="content-holder">
                                                                        <div className="setting-item border_bottom pb_30 pt-4">
                                                                            <div className="d-flex align-items-start">
                                                                                <label className="btn-switch m-0 me-3">
                                                                                    <input type="checkbox" className="" id="booking-start-time-btn" value="" checked />
                                                                                    <span className="checkbox-slider"></span>
                                                                                </label>
                                                                                <div className="d-flex flex-column">
                                                                                    <label className="color-black fw-bold mb-1">I want the bookings to start immediately.</label>
                                                                                    <p className="mt-2 fs-14 d-block mb-0">Disable this option if you want to start your booking from a specific date and time.</p>
                                                                                </div>
                                                                            </div>
                                                                            <div className="booking-start-time-holder" >
                                                                                <div className="form-group pt_30">
                                                                                    <label className="form-label fs-16">Booking starts on</label>
                                                                                    <p className="mt-2 fs-14 d-block mb-0">Specify the date and time when you want the booking to start.</p>
                                                                                    <div className="row g-3">
                                                                                        <div className="col-md-6">
                                                                                            <label className="form-label mt-3 fs-6">Event Date.*</label>
                                                                                            <div className="loc-group position-relative">
                                                                                                <input className="form-control h_50 datepicker-here" data-language="en" type="text" placeholder="MM/DD/YYYY" value="" />
                                                                                                <span className="absolute-icon"><i className="fa-solid fa-calendar-days"></i></span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-md-6">
                                                                                            <div className="clock-icon">
                                                                                                <label className="form-label mt-3 fs-6">Time</label>
                                                                                                <Select
                                                                                                    className='eventox-select'
                                                                                                    options={eventTime}
                                                                                                    styles={colourStyles}
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="setting-item border_bottom pb_30 pt_30">
                                                                            <div className="d-flex align-items-start">
                                                                                <label className="btn-switch m-0 me-3">
                                                                                    <input type="checkbox" className="" id="booking-end-time-btn" value="" checked />
                                                                                    <span className="checkbox-slider"></span>
                                                                                </label>
                                                                                <div className="d-flex flex-column">
                                                                                    <label className="color-black fw-bold mb-1">I want the bookings to continue until my event ends.</label>
                                                                                    <p className="mt-2 fs-14 d-block mb-0">Disable this option if you want to end your booking from a specific date and time.</p>
                                                                                </div>
                                                                            </div>
                                                                            <div className="booking-end-time-holder">
                                                                                <div className="form-group pt_30">
                                                                                    <label className="form-label fs-16">Booking ends on</label>
                                                                                    <p className="mt-2 fs-14 d-block mb-0">Specify the date and time when you want the booking to start.</p>
                                                                                    <div className="row g-3">
                                                                                        <div className="col-md-6">
                                                                                            <label className="form-label mt-3 fs-6">Event Date.*</label>
                                                                                            <div className="loc-group position-relative">
                                                                                                <input className="form-control h_50 datepicker-here" data-language="en" type="text" placeholder="MM/DD/YYYY" value="" />
                                                                                                <span className="absolute-icon"><i className="fa-solid fa-calendar-days"></i></span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-md-6">
                                                                                            <div className="clock-icon">
                                                                                                <label className="form-label mt-3 fs-6">Time</label>
                                                                                                <Select
                                                                                                    className='eventox-select'
                                                                                                    options={eventTime}
                                                                                                    styles={colourStyles}
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="setting-item border_bottom pb_30 pt_30">
                                                                            <div className="d-flex align-items-start">
                                                                                <label className="btn-switch m-0 me-3">
                                                                                    <input type="checkbox" className="" id="passing-service-charge-btn" value="" checked />
                                                                                    <span className="checkbox-slider"></span>
                                                                                </label>
                                                                                <div className="d-flex flex-column">
                                                                                    <label className="color-black fw-bold mb-1">I want my customers to pay the applicable service fees at the time when they make the bookings.</label>
                                                                                    <p className="mt-2 fs-14 d-block mb-0 pe_right">Passing your service charge means your attendees will pay your service charge in addition to the ticket price. </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="setting-item border_bottom pb_30 pt_30">
                                                                            <div className="d-flex align-items-start">
                                                                                <label className="btn-switch m-0 me-3">
                                                                                    <input type="checkbox" className="" id="refund-policies-btn" value="" checked />
                                                                                    <span className="checkbox-slider"></span>
                                                                                </label>
                                                                                <div className="d-flex flex-column">
                                                                                    <label className="color-black fw-bold mb-1">I do not wish to offer my customers with option to cancel their orders and receive refund.</label>
                                                                                    <p className="mt-2 fs-14 d-block mb-0">Disable this slider if you want to let your customers cancel their order and select a refund policy.</p>
                                                                                </div>
                                                                            </div>
                                                                            <div className="refund-policies-holder">
                                                                                <div className="refund-policies-content border_top mt-4">
                                                                                    <div className="row grid-padding-8">
                                                                                        <div className="col-md-12 mb-6">
                                                                                            <div className="refund-method">
                                                                                                <div className="form-group mb-0">
                                                                                                    <label className="brn-checkbox-radio mb-0 mt-4">
                                                                                                        <input type="radio" name="refund_policy_id" value="refund-id-1" className="form-check-input br-checkbox refund-policy1" />
                                                                                                        <span className="fs-14 fw-bold ms-xl-2">I wish to offer my customers with option to cancel their orders. However, I will handle refund manually.</span>
                                                                                                        <span className="ms-xl-4 d-block sub-label mt-2 mb-4">Select this option if you want to refund your customer manually.</span>
                                                                                                    </label>
                                                                                                    <div className="refund-input-content" data-method="refund-id-1">
                                                                                                        <div className="input-content mb-3">
                                                                                                            <label className="color-black mb-2 fs-14 fw-bold">Cancellation must be made<span className="red">*</span></label>
                                                                                                            <div className="d-block d-md-flex align-items-center flex-wrap flex-lg-wrap-reverse">
                                                                                                                <div className="col-md-4 pl-0">
                                                                                                                    <div className="input-group mr-3 mx-width-135 input-number">
                                                                                                                        <input type="number" min="0" max="30" className="form-control" placeholder="" />
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div className="input-sign ms-md-3 mt-3 mb-3">days before the event</div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="refund-method">
                                                                                                <label className="brn-checkbox-radio mb-0 mt-4">
                                                                                                    <input type="radio" name="refund_policy_id" value="refund-id-2" className="form-check-input br-checkbox refund-polic-2" />
                                                                                                    <span className="fs-14 fw-bold ms-xl-2">I wish to offer my customers with option to cancel their orders and receive refund automatically.</span>
                                                                                                    <span className="ms-xl-4 d-block sub-label mt-2 mb-4">Select this option if you want to refund your customer automatically.</span>
                                                                                                </label>
                                                                                                <div className="refund-input-content" data-method="refund-id-2">
                                                                                                    <div className="input-content mb-3">
                                                                                                        <label className="color-black mb-2 fs-14 fw-bold">Cancellation must be made <span className="red">*</span></label>
                                                                                                        <div className="d-block d-md-flex align-items-center flex-wrap flex-lg-wrap-reverse">
                                                                                                            <div className="col-md-4">
                                                                                                                <div className="input-group input-number">
                                                                                                                    <input type="number" min="0" max="30" className="form-control" placeholder="" />
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div className="input-sign ms-md-3 mt-3 mb-3">days before the event</div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="input-content mb-3">
                                                                                                        <label className="color-black mb-2 fs-14 fw-bold">Refund amount <span className="red">*</span></label>
                                                                                                        <div className="d-block d-md-flex align-items-center flex-wrap flex-lg-wrap-reverse">
                                                                                                            <div className="col-md-4">
                                                                                                                <div className="input-group loc-group position-relative">
                                                                                                                    <input type="text" value="" className="form-control" placeholder="" />
                                                                                                                    <span className="percentage-icon"><i className="fa-solid fa-percent"></i></span>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div className="input-sign ms-md-3 mt-3 mb-3">days before the event</div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="setting-item border_bottom pb_30 pt_30">
                                                                            <div className="d-flex align-items-start">
                                                                                <label className="btn-switch m-0 me-3">
                                                                                    <input type="checkbox" className="" id="ticket-instructions-btn" value="" checked />
                                                                                    <span className="checkbox-slider"></span>
                                                                                </label>
                                                                                <div className="d-flex flex-column">
                                                                                    <label className="color-black fw-bold mb-1">I do not require adding any special instructions on the tickets.</label>
                                                                                    <p className="mt-2 fs-14 d-block mb-0">Use this space to provide any last minute checklists your attendees must know in order to attend your event. Anything you provide here will be printed on your ticket.</p>
                                                                                </div>
                                                                            </div>
                                                                            <div className="ticket-instructions-holder">
                                                                                <div className="ticket-instructions-content mt-4">
                                                                                    <textarea className="form-textarea" placeholder="About"></textarea>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="setting-item pb-0 pt_30">
                                                                            <div className="d-flex align-items-start">
                                                                                <label className="btn-switch m-0 me-3">
                                                                                    <input type="checkbox" className="" id="tags-btn" value="" checked />
                                                                                    <span className="checkbox-slider"></span>
                                                                                </label>
                                                                                <div className="d-flex flex-column">
                                                                                    <label className="color-black fw-bold mb-1">I do not want to add tags in my event</label>
                                                                                    <p className="mt-2 fs-14 d-block mb-0">Use relevant words as your tags to improve the discoverability of your event. </p>
                                                                                </div>
                                                                            </div>
                                                                            <div className="tags-holder">
                                                                                <div className="ticket-instructions-content tags-container mt-4">
                                                                                    <input className="form-control tags-input" type="text" placeholder="Type your tags and press enter" />
                                                                                    <div className="tags-list">

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                    </div>
                                    <AddNewTicketModal
                                        ticketModalOpen={ticketModalOpen}
                                        handleCloseTicketModal={handleCloseTicketModal}
                                        eventSlug={eventSlug}
                                        getTickets={getTickets}
                                    />
                                    <div className="step-footer step-tab-pager mt-4">
                                        {renderStepButton()}
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditEvent