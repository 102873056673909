import React, { useEffect, useState } from 'react'
import { TicketsType } from '../../../types/Events';
import { updateTicket } from '../../../api';

type TicketCardProps = {
    tickets:TicketsType,
    onEditTicket:(ticket:TicketsType)=>void
}

const TicketCard = (props: TicketCardProps) => {
    const {onEditTicket,tickets} = props
    const { ticketType, price, maxCount, ticketStatus, currentCount, createdAt } = tickets;

    const [onAction, setOnAction] = useState(false)
    const [isActive, setIsActive] = useState(false)
    
    const onUpdateStatus=async()=>{
        let status = isActive?'inactive':'active'
        let { ticketTypeId, ...data } = tickets;
        data.ticketStatus = status;
        try{
            const res=await updateTicket(ticketTypeId,data)
            if(res.status===200){
                setIsActive(!isActive)
            }
           
        }catch(error){
            console.log(error)
        }
    }
    useEffect(() => {
        if(ticketStatus){
        setIsActive(ticketStatus==='active'?true:false)
      }

      return () => {
        
      }
    }, [ticketStatus])
    


    return (
        <div className="price-ticket-card mt-4">
            <div className="price-ticket-card-head d-md-flex flex-wrap align-items-start justify-content-between position-relative p-4">
                <div className="d-flex align-items-center top-name">
                    <div className="icon-box">
                        <span className="icon-big rotate-icon icon icon-purple">
                            <i className="fa-solid fa-ticket"></i>
                        </span>
                        <h5 className="fs-16 mb-1 mt-1">{`${ticketType}-$${price}`}</h5>
                        <p className="text-gray-50 m-0"><span className="visitor-date-time">{createdAt}</span></p>
                    </div>
                </div>
                <div className="d-flex align-items-center">
                   
                    <label className="btn-switch tfs-8 mb-0 me-4 mt-1" >
                        <input type="checkbox"  checked={isActive?true:false} onClick={onUpdateStatus} />
                        <span className="checkbox-slider"></span>
                    </label>
                    <div className="dropdown dropdown-default dropdown-text dropdown-icon-item">
                        <button className={`option-btn-1 ${onAction?'show':''}`} type="button" onClick={()=>setOnAction(!onAction)}>
                            <i className="fa-solid fa-ellipsis-vertical"></i>
                        </button>
                        <div className={`dropdown-menu dropdown-menu-end ${onAction?'show ticket-dropdown ':''}`}>
                            <button className="dropdown-item" onClick={()=>onEditTicket(tickets)}><i className="fa-solid fa-pen me-3"></i>Edit</button>
                            <button className="dropdown-item"><i className="fa-solid fa-trash-can me-3"></i>Delete</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="price-ticket-card-body border_top p-4">
                <div className="full-width d-flex flex-wrap justify-content-between align-items-center">
                    <div className="icon-box">
                        <div className="icon me-3">
                            <i className="fa-solid fa-ticket"></i>
                        </div>
                        <span className="text-145">Total tickets</span>
                        <h6 className="coupon-status">{maxCount.toString()==="1000000"?'Unlimited':maxCount}</h6>
                    </div>
                    <div className="icon-box">
                        <div className="icon me-3">
                            <i className="fa-solid fa-users"></i>
                        </div>
                        <span className="text-145">Sold Ticket</span>
                        <h6 className="coupon-status">{currentCount}</h6>
                    </div>
                    <div className="icon-box">
                        <div className="icon me-3">
                            <i className="fa-solid fa-cart-shopping"></i>
                        </div>
                        <span className="text-145">Status</span>
                        <h6 className="coupon-status">{isActive?'Active':'In Active'}</h6>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TicketCard