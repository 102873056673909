import React from 'react'
import { Link } from 'react-router-dom'

type ChooseEventTypeProps = {}

const ChooseEventType = (props: ChooseEventTypeProps) => {
  return (
    <div className="col-md-12">
        <div className="event-dt-block p-80">
			<div className="container">
				<div className="row justify-content-center">
					<div className="col-lg-12 col-md-12">
						<div className="main-title text-center checkout-title">
							<h3>Create New Event</h3>
						</div>
					</div>
					<div className="col-xl-6 col-lg-8 col-md-12">
						<div className="create-block">
							<div className="row">
								{/* <div className="col-md-6">
									<div className="main-card create-card mt-4">
										<div className="create-icon">
											<i className="fa-solid fa-video"></i>
										</div>
										<h4>Create an Online Event</h4>
										<Link to="/create-online-event" className="main-btn btn-hover h_40 w-100">Create<i className="fa-solid fa-arrow-right ms-2"></i></Link>
									</div>
								</div> */}
								<div className="col-md-6">
									<div className="main-card create-card mt-4">
										<div className="create-icon">
											<i className="fa-solid fa-location-dot"></i>
										</div>
										<h4>Create an Venue Event</h4>
										<Link to="/create-venue-event" className="main-btn btn-hover h_40 w-100">Create<i className="fa-solid fa-arrow-right ms-2"></i></Link>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
    </div>
  )
}

export default ChooseEventType