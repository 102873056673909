import React from 'react'
import { Link } from 'react-router-dom';

type NormalTableProps = {
    columns: any[];
    data: any[];
}

const NormalTable = (props: NormalTableProps) => {
    // const { columns, data } = props;
    return (
        <div className="table-card mt-4">
            <div className="main-table">
                <div className="table-responsive">
                    <table className="table">
                        <thead className="thead-dark">
                            <tr>
                                <th scope="col">ID</th>
                                <th scope="col">Name</th>
                                <th scope="col">Event Name</th>
                                <th scope="col">Date</th>
                                <th scope="col">Reference</th>
                                <th scope="col">Status</th>
                                <th scope="col">Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>5291</td>
                                <td>Larry Paige</td>
                                <td><Link to="#" target="_blank">Astrology on sunday event</Link></td>
                                <td>04/22/2022</td>
                                <td>E1021100NA8711001</td>
                                <td><span className="status-circle red-circle"></span>Canceled</td>
                                <td>AUD $0.00</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default NormalTable