import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import '../../../assets/css/vertical-responsive-menu.min.css'
import { images } from '../../../assets/images'
import SideNavMenu from './SideNavMenu'
type HeaderProps = {
  onChangeMenu:()=>void;
}

const Header = (props: HeaderProps) => {
  const {onChangeMenu}=props
  const [showDropDown, setShowDropDown] = useState(false)
  const [showDrawer, setShowDrawer] = useState(false)
  const [minifyNav, setMinifyNav] = useState(false)
  const [mobileNavBarOpen, setMobileNavBarOpen] = useState(false)
  const onClickToggleBar=()=>{
    setMinifyNav(!minifyNav)
    onChangeMenu()
  }
  return (
    <>
    <header className="header">
      <div className="header-inner">
        <nav className="navbar navbar-expand-lg bg-barren barren-head navbar fixed-top justify-content-sm-start pt-0 pb-0 ps-lg-0 pe-2">
          <div className="container-fluid ps-0">
            <button type="button" id="toggleMenu" className="toggle_menu" onClick={()=>setMobileNavBarOpen(!mobileNavBarOpen)}>
              <i className="fa-solid fa-bars-staggered"></i>
            </button>
            <button id="collapse_menu" className="collapse_menu me-4" onClick={onClickToggleBar}>
              <i className="fa-solid fa-bars collapse_menu--icon "></i>
              <span className="collapse_menu--label"></span>
            </button>
            <button className="navbar-toggler order-3 ms-2 pe-0" onClick={()=>setShowDrawer(!showDrawer)}>
              <span className="navbar-toggler-icon">
                <i className="fa-solid fa-bars"></i>
              </span>
            </button>
            <Link className="navbar-brand order-1 order-lg-0 ml-lg-0 ml-2 me-auto" to={"/"}>
              <div className="res-main-logo">
                <img src={images.logo} alt="" />
              </div>
              <div className="main-logo" id="logo">
                <img src={images.logo} alt="" />
                <img className="logo-inverse" src={images.logo} alt="" />
              </div>
            </Link>
            <div className={`offcanvas offcanvas-start ${showDrawer?'show':''}`} id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
              <div className="offcanvas-header">
                <div className="offcanvas-logo" id="offcanvasNavbarLabel">
                  <img src={images.logo} alt="" />
                </div>
                <button type="button" className="close-btn"  aria-label="Close" onClick={()=>setShowDrawer(!showDrawer)}>
                  <i className="fa-solid fa-xmark"></i>
                </button>
              </div>
              <div className="offcanvas-body">
                <div className="offcanvas-top-area">
                  <div className="create-bg">
                    <Link to="/create-event" className="offcanvas-create-btn">
                      <i className="fa-solid fa-calendar-days"></i>
                      <span>Create Event</span>
                    </Link>

                  </div>
                </div>
                <ul className="navbar-nav justify-content-end flex-grow-1 pe_5">
                  <li className="nav-item">
                    <Link to="/home" className="nav-link">
                      <i className="fa-solid fa-right-left me-2"></i>My Home
                    </Link>

                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="https://eventox.co/events/" target='_blank' rel="noreferrer">
                      <i className="fa-solid fa-compass me-2"></i>Explore Events
                    </a>
                  </li>
                </ul>
              </div>

            </div>
            <div className="right-header order-2">
              <ul className="align-self-stretch">
                <li>
                  <Link to="/create-event" className="create-btn btn-hover">
                  
                    <i className="fa-solid fa-calendar-days"></i>
                    <span>Create Event</span>
                    </Link>
                </li>
                <li className="dropdown account-dropdown order-3">
                  <button className="account-link" id="accountClick" onClick={() => setShowDropDown(!showDropDown)}>
                    <img src={images.defaultProfileIcon} alt="" />
                    <i className="fas fa-caret-down arrow-icon"></i>
                  </button>
                  <ul className={`dropdown-menu dropdown-menu-account dropdown-menu-end eventox-dropdown ${showDropDown ? 'show' : ''}`} aria-labelledby="accountClick">
                    <li>
                      <div className="dropdown-account-header">
                        <div className="account-holder-avatar">
                          <img src={images.defaultProfileIcon} alt="" />
                        </div>
                        <h5>John Doe</h5>
                        <p>johndoe@example.com</p>
                      </div>
                    </li>
                    <li className="profile-link">
                      <Link to="/profile" className="link-item">My Profile</Link>
                      <Link to="/" className="link-item">Sign Out</Link>
                    </li>
                  </ul>
                </li>
               
              </ul>
            </div>
          </div>
        </nav>
        <div className="overlay"></div>
      </div>
    </header>
    <SideNavMenu minifyNav={minifyNav} mobileNavOpen={mobileNavBarOpen}/>
    </>
  )
}

export default Header