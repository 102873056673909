import React, { useEffect, useState } from 'react'
// import { images } from '../../assets/images'
// import EventNotFound from '../../components/common/EventCard/EventNotFound'
import EventListCard from '../../components/common/EventCard/EventListCard'
import { getAllOrganizerEvents } from '../../api'
import EventNotFound from '../../components/common/EventCard/EventNotFound'
import { EventType } from '../../types/Events'
import { images } from '../../assets/images'

type EventsProps = {}

const Events = (props: EventsProps) => {
    const [eventList, setEventList] = useState<EventType[]>([])
    const [onlineEventList, setOnlineEventList] = useState<EventType[]>([])
    const [offlineEventList, setOfflineEventList] = useState<EventType[]>([])
    const [currentTab, setCurrentTab] = useState("all")

    useEffect(() => {
        try {
            const fetchEvents = async () => {
                const response = await getAllOrganizerEvents();
                setEventList(response)
                const onlineEvents = response.filter((event: EventType) => event.eventType === "online")
                const offlineEvents = response.filter((event: EventType) => event.eventType === "offline")
                setOnlineEventList(onlineEvents)
                setOfflineEventList(offlineEvents)

            }
            fetchEvents();
        } catch (error) { }
        return () => {
        }
    }, [])

    const renderEventTable = () => {

        if (currentTab === "online") {
            return onlineEventList.map((event: EventType) => <EventListCard eventName={event.eventName} description={event.eventDescription} bannerImg={event.eventBanner !== "" ? event.eventBanner : images.noEvent} eventStatus="Live" eventDate={event.eventDate} totalTicket={100} soldTicket={50} />)
        } else if (currentTab === "offline") {
            return offlineEventList.map((event: EventType) => <EventListCard eventName={event.eventName} description={event.eventDescription} bannerImg={event.eventBanner !== "" ? event.eventBanner : images.noEvent} eventStatus="Live" eventDate={event.eventDate} totalTicket={100} soldTicket={50} />)
        } else {
            return eventList.map((event: EventType) => <EventListCard eventName={event.eventName} description={event.eventDescription} bannerImg={event.eventBanner !== "" ? event.eventBanner : images.noEvent} eventStatus="Live" eventDate={event.eventDate} totalTicket={100} soldTicket={50} />)
        }

    }
    const renderCount = () => {
        if (currentTab === "online") {
            return onlineEventList.length
        } else if (currentTab === "offline") {
            return offlineEventList.length
        } else {
            return eventList.length
        }
    }

    return (
        <>
            <div className="row">
                <div className="col-md-12">
                    <div className="d-main-title">
                        <h3><i className="fa-solid fa-calendar-days me-3"></i>Events</h3>
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="main-card mt-5">
                        <div className="dashboard-wrap-content p-4">
                            <h5 className="mb-4">Events ({renderCount()})</h5>
                            <div className="d-md-flex flex-wrap align-items-center">
                                <div className="dashboard-date-wrap">
                                    <div className="form-group">
                                        <div className="relative-input position-relative">
                                            <input className="form-control h_40" type="text" placeholder="Search by event name, status" value="" />
                                            <i className="uil uil-search"></i>
                                        </div>
                                    </div>
                                </div>
                                <div className="rs ms-auto mt_r4">
                                    <div className="nav custom2-tabs btn-group" role="tablist">
                                        <button className={`tab-link ${currentTab === 'all' && 'active'}`} onClick={() => setCurrentTab("all")}>All Event (<span className="total_event_counter">{eventList.length}</span>)</button>
                                        <button className={`tab-link ${currentTab === 'online' && 'active'}`} onClick={() => setCurrentTab("online")}>Online Event (<span className="total_event_counter">{onlineEventList.length}</span>)</button>
                                        <button className={`tab-link ${currentTab === 'offline' && 'active'}`} onClick={() => setCurrentTab("offline")}>Venue Event (<span className="total_event_counter">{offlineEventList.length}</span>)</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="event-list">
                        <div className="tab-content">
                            {eventList && eventList.length <= 0 && <EventNotFound />}

                            {eventList && eventList.length >= 0 && renderEventTable()}

                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default Events