// src/store/organizer/reducer.ts
import {
    OrganizerState,
    OrganizerActionTypes,
    GET_ORGANIZERS_REQUEST,
    GET_ORGANIZERS_SUCCESS,
    GET_ORGANIZERS_FAILURE,
  } from './types';
  
  const initialState: OrganizerState = {
    organizers: [],
    loading: false,
    error: null,
  };
  
  const organizerReducer = (
    state = initialState,
    action: OrganizerActionTypes
  ): OrganizerState => {
    switch (action.type) {
      case GET_ORGANIZERS_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
      case GET_ORGANIZERS_SUCCESS:
        return {
          ...state,
          loading: false,
          organizers: action.payload,
        };
      case GET_ORGANIZERS_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default organizerReducer;
  