import React, { useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'

type SideNavMenuProps = {
  minifyNav:boolean;
  mobileNavOpen:boolean
}

const SideNavMenu = (props: SideNavMenuProps) => {
  const {minifyNav,mobileNavOpen}=props
  const location=useLocation()
  
  useEffect(() => {
     // Add active class on menu item
     let links = document.querySelectorAll('.menu--link')
     let link = location.pathname
     links.forEach((el) => {
       if (el.getAttribute('href') === link) {
         el.classList.add('active')
       }else{
        el.classList.remove('active')
       }
     })
  }, [location])
  
  const onClickLogout=()=>{
    localStorage.removeItem('token')
    window.location.href='/login'
  }
 
  return (
    <nav className={`vertical_nav ${minifyNav?'vertical_nav__minify':''} ${mobileNavOpen?'vertical_nav__opened':''}`}>
      <div className="left_section menu_left" id="js-menu">
        <div className="left_section">
          <ul>
            <li className="menu--item">
              <Link to="/home" className="menu--link active" title="Dashboard">
                <i className="fa-solid fa-gauge menu--icon"></i>
                <span className="menu--label">Dashboard</span>
              </Link>
            </li>
            <li className="menu--item">
              <Link to="/events" className="menu--link" title="Events">
                <i className="fa-solid fa-calendar-days menu--icon"></i>
                <span className="menu--label">Events</span>
              </Link>
            </li>
            <li className="menu--item">
              <Link to="/promotion" className="menu--link" title="Promotion">
                <i className="fa-solid fa-rectangle-ad menu--icon"></i>
                <span className="menu--label">Promotion</span>
              </Link>
            </li>
            {/* <li className="menu--item">
              <Link to="/contacts" className="menu--link" title="Contact List">
                <i className="fa-regular fa-address-card menu--icon"></i>
                <span className="menu--label">Contact List</span>
              </Link>
            </li> */}
            <li className="menu--item">
              <Link to="/payout" className="menu--link" title="Payouts">
                <i className="fa-solid fa-credit-card menu--icon"></i>
                <span className="menu--label">Payouts</span>
              </Link>
            </li>
            <li className="menu--item">
              <Link to="/reports" className="menu--link" title="Reports">
                <i className="fa-solid fa-chart-pie menu--icon"></i>
                <span className="menu--label">Reports</span>
              </Link>
            </li>
            {/* <li className="menu--item">
              <Link to="/subscription" className="menu--link" title="Subscription">
                <i className="fa-solid fa-bahai menu--icon"></i>
                <span className="menu--label">Subscription</span>
              </Link>
            </li> */}
            {/* <li className="menu--item">
              <Link to="#" className="menu--link" title="Conversion Setup">
                <i className="fa-solid fa-square-plus menu--icon"></i>
                <span className="menu--label">Conversion Setup</span>
              </Link>
            </li> */}
            <li className="menu--item">
              <Link to="/profile" className="menu--link" title="About">
                <i className="fa-solid fa-circle-info menu--icon"></i>
                <span className="menu--label">About</span>
              </Link>
            </li>
            {/* <li className="menu--item">
              <Link to="/my-team" className="menu--link team-lock" title="My Team"> 
                <i className="fa-solid fa-user-group menu--icon"></i>
                <span className="menu--label">My Team</span>
              </Link>
            </li> */}
             <li className="menu--item">
              <Link to="/login" onClick={onClickLogout} className="menu--link" title="Logout">
                <i className="fa-solid fa-sign-out menu--icon"></i>
                <span className="menu--label">Logout</span>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}

export default SideNavMenu