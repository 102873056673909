
// src/routes/PrivateRoute.tsx
import React from 'react';
import { Navigate } from 'react-router-dom';
import Layout from '../components/layout/Organizer/Layout';

const PrivateRoute = ({ children }: { children: JSX.Element }) => {
  const isAuth = !!localStorage.getItem('token');

  return isAuth ? (
    <Layout>
      {children}
    </Layout>
  ) : (
    <Navigate to="/login" />
  );
};

export default PrivateRoute;