// src/store/organizer/thunks.ts
import { AppThunk } from '../store';
import {
  getOrganizersRequest,
  getOrganizersSuccess,
  getOrganizersFailure,
} from './actions';
import { getOrganizerProfile } from '../../api';

export const fetchOrganizersThunk = (): AppThunk => async (dispatch:any) => {
  dispatch(getOrganizersRequest());

  try {
    const organizers = await getOrganizerProfile();
    dispatch(getOrganizersSuccess(organizers));
  } catch (error:any) {
    dispatch(getOrganizersFailure(error.message));
  }
};
