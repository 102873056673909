// src/context/AuthContext.tsx
import React, { createContext, useContext, ReactNode, useState, useEffect } from 'react';
import { getAuthToken } from '../utils/auth';

interface AuthContextType {
  isAuthenticated: boolean;
  login: () => void;
  logout: () => void;
}

const AuthContext = createContext<AuthContextType>(null!);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const token = getAuthToken();
    setIsAuthenticated(!!token);
  }, []);
  const login = () => {
    let token = getAuthToken();
    if (token !== null && token !== undefined && token !== "") {
      setIsAuthenticated(true)
    }else{
      setIsAuthenticated(false)
    }
  }


  const logout = () => {
    setIsAuthenticated(false);
  }

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);