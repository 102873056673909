import { Modal } from '@mui/material'
import React, { useState } from 'react'
import { createTicket } from '../../api';

type AddNewTicketModalProps = {
    ticketModalOpen: boolean;
    handleCloseTicketModal: () => void;
    eventSlug: string;
    getTickets:(val:string)=>void;

}

const AddNewTicketModal = (props: AddNewTicketModalProps) => {
    const { ticketModalOpen, handleCloseTicketModal, eventSlug,getTickets } = props;
    const [isUnlimited, setIsUnlimited] = useState(true)
    const [ticketFormData, setTicketFormData] = useState({
        ticketType: '',
        price: 0,
        maxCount: 0,
        ticketStatus: 'active',
        currentCount: 0,
        ticketOrder:0
    })

    const handleChange = (e: { target: { name: any; value: any; }; }) => {
        const { name, value } = e.target;
        setTicketFormData(prevState => ({
            ...prevState,
            [name]: value,
        }));
    };
    const handleSave = async() => {
        try {
            let data = {
                ...ticketFormData,
                maxCount: isUnlimited ? 1000000 : ticketFormData.maxCount,
                eventSlug,
                ticketStatus: 'active',
                currentCount: 0
            }
            const response = await createTicket(data);
            if(response){
                handleCloseTicketModal();
                getTickets(eventSlug)
            }
            
        } catch (error) {
            console.log(error)
        }
    }
    return (
        <Modal
            open={ticketModalOpen}
            onClose={handleCloseTicketModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <div className='ticket-modal'>
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="groupTicketModalLabel">Create New Ticket</h5>
                            <button type="button" className="close-model-btn" data-bs-dismiss="modal" aria-label="Close" onClick={handleCloseTicketModal}><i className="uil uil-multiply"></i></button>
                        </div>
                        <div className="modal-body">
                            <div className="model-content main-form">
                                <div className="row">
                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group mt-4">
                                            <label className="form-label">Ticket Name*</label>
                                            <input className="form-control h_40" type="text" placeholder="Enter Ticket Type - Group Name (E.g Gold - Family Pass)" value={ticketFormData.ticketType} name='ticketType' onChange={handleChange} />
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group mt-4">
                                            <label className="form-label">Ticket Order*</label>
                                            <input className="form-control h_40" type="text" placeholder="Enter Ticket Type - Group Name (E.g Gold - Family Pass)" value={ticketFormData.ticketOrder} name='ticketOrder' onChange={handleChange} />
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12">
                                        <div className="main-card p-4 mt-4">
                                            <div className="form-label mb-4 fs-16">Ticket Restrictions</div>
                                            <div className="form-group border_bottom">
                                                
                                                <div className="d-flex align-items-center flex-wrap pb-4 flex-nowrap">
                                                    <h4 className="fs-14 mb-0 me-auto">Total number of tickets available</h4>
                                                    <label className="btn-switch m-0 me-3">
                                                        <input type="checkbox" id="is-restrict-total-ticket2" checked={isUnlimited} onChange={() => setIsUnlimited(!isUnlimited)} />
                                                        <span className="checkbox-slider"></span>
                                                    </label>
                                                    <div>
                                                        <label className="fs-12 m-0">Unlimited</label>
                                                    </div>
                                                </div>
                                                {!isUnlimited &&
                                                    <div className="p-0 mb-4 total_ticket_per_level2">
                                                        <div className="form-group">
                                                            <div className="input-number">
                                                                <input className="form-control h_40" type="number" min="0" max="500" placeholder="Enter Total Tickets" value={ticketFormData.maxCount} name='maxCount' onChange={handleChange} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </div>

                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group mt-4">
                                            <label className="form-label mb-2 fs-14">Ticket Price*</label>
                                            <input className="form-control h_40" type="number" placeholder="Enter Ticket Price" value={ticketFormData.price} onChange={handleChange} name='price' />
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="co-main-btn min-width btn-hover h_40" onClick={handleCloseTicketModal}>Cancel</button>
                            <button type="button" className="main-btn min-width btn-hover h_40" onClick={handleSave}>Save</button>
                        </div>
                    </div>
                </div>
            </div>

        </Modal>
    )
}

export default AddNewTicketModal