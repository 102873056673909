export interface Organizer {
    organizerId: number;
    name?: string;
    description?: string;
    profilePic?: string;
    email?: string;
    phone?: string;
    address?: string;
    country?: string;
    currency?: string;
    status?: string;
  }

  export interface OrganizerState {
    organizers: Organizer[];
    loading: boolean;
    error: string | null;
  }
export const GET_ORGANIZERS_REQUEST = 'GET_ORGANIZERS_REQUEST';
export const GET_ORGANIZERS_SUCCESS = 'GET_ORGANIZERS_SUCCESS';
export const GET_ORGANIZERS_FAILURE = 'GET_ORGANIZERS_FAILURE';

interface GetOrganizersRequestAction {
    type: typeof GET_ORGANIZERS_REQUEST;
  }
  
  interface GetOrganizersSuccessAction {
    type: typeof GET_ORGANIZERS_SUCCESS;
    payload: Organizer[];
  }
  
  interface GetOrganizersFailureAction {
    type: typeof GET_ORGANIZERS_FAILURE;
    payload: string;
  }
  
  export type OrganizerActionTypes =
    | GetOrganizersRequestAction
    | GetOrganizersSuccessAction
    | GetOrganizersFailureAction;