// src/store/organizer/actions.ts
import {
    GET_ORGANIZERS_REQUEST,
    GET_ORGANIZERS_SUCCESS,
    GET_ORGANIZERS_FAILURE,
    OrganizerActionTypes,
    Organizer,
  } from './types';
  
  export const getOrganizersRequest = (): OrganizerActionTypes => ({
    type: GET_ORGANIZERS_REQUEST,
  });
  
  export const getOrganizersSuccess = (
    organizers: Organizer[]
  ): OrganizerActionTypes => ({
    type: GET_ORGANIZERS_SUCCESS,
    payload: organizers,
  });
  
  export const getOrganizersFailure = (error: string): OrganizerActionTypes => ({
    type: GET_ORGANIZERS_FAILURE,
    payload: error,
  });
  