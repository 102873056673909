/**
 * Retrieves the authentication token.
 * @returns {string|null} - The auth token or null if not found.
 */
export const getAuthToken = () => {

  if (typeof window !== 'undefined') {
    return localStorage.getItem('token');
  }
  return null;
};
export const storeAuthToken = (token) => {

  if (typeof window !== 'undefined') {
    return localStorage.setItem('token', token);
  }
  return null;
};



export const getUserDetails = () => {
  if (typeof window !== 'undefined') {
    const authUser = localStorage.getItem('authUser');
    if (authUser !== undefined) {
      const userDetails = JSON.parse(authUser)?.user;
      return userDetails;
    }
  }
  return null;
};
