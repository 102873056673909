import React from 'react'
import { images } from '../../assets/images'
import DashboardWidget from '../../components/common/DashboardWidget/DashboardWidget'
import { useDispatch, useSelector } from 'react-redux'
import { fetchOrganizersThunk } from '../../store/organizer/thunks'
import { RootState } from '../../store/rootReducer'
import { AppDispatch } from '../../store/store'

type Props = {}

const HomePage = (props: Props) => {
  const dispatch: AppDispatch = useDispatch();
  const { organizers, loading, error } = useSelector((state: RootState) => state.organizer);
  useSelector((state: any) => state)
  React.useEffect(() => {
    dispatch(fetchOrganizersThunk());
  }, [dispatch])

  return (
    <div className="row">
      <div className="col-12">
        <div className="d-main-title">
          <h3><i className="fa-solid fa-gauge me-3"></i>Dashboard</h3>
        </div>
        <div className="col-md-12">
          <div className="main-card add-organisation-card p-4 mt-5">
            <div className="ocard-left">
              <div className="ocard-avatar">
                <img src={images.defaultProfileIcon} alt="" />
              </div>
              <div className="ocard-name">
                <h4>John Doe</h4>
                <span>My Organisation</span>
              </div>
            </div>
            {/* <div className="ocard-right">
								<button className="pe-4 ps-4 co-main-btn min-width" data-bs-toggle="modal" data-bs-target="#addorganisationModal"><i className="fa-solid fa-plus"></i>Add Organisation</button>
							</div> */}
          </div>
          <div className="main-card mt-4">
            <div className="dashboard-wrap-content">
              <div className="dashboard-report-content">
                <div className="row">
                  <div className="col-xl-3 col-lg-6 col-md-6">
                    <DashboardWidget mainTitle="Total Events" subTitle="12" description="Last 30 days" cardClass="purple" icon="fa-calendar-days" />
                  </div>
                  <div className="col-xl-3 col-lg-6 col-md-6">
                    <DashboardWidget mainTitle="Total Revenue" subTitle="$ 1200" description="Last 30 days" cardClass="red" icon="fa-credit-card" />
                  </div>
                  <div className="col-xl-3 col-lg-6 col-md-6">
                    <DashboardWidget mainTitle="Total Tickets" subTitle="120" description="Last 30 days" cardClass="info" icon="fa-ticket" />
                  </div>
                  <div className="col-xl-3 col-lg-6 col-md-6">
                    <DashboardWidget mainTitle="Total Attendees" subTitle="1200" description="Last 30 days" cardClass="success" icon="fa-user" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  )
}

export default HomePage