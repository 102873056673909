export const images = {
    logo: require('./eventox/logo.png'),
    defaultProfileIcon: require('./eventox/default-profile-icon.jpg'),
    calendar:require('./eventox/calendar.png'),
    ticket:require('./eventox/ticket.png'),
    noEvent:require('./eventox/no-event-placeholder.jpg'),
    standardWebinar:require('./eventox/icon/standard-webinar.png'),
    training:require('./eventox/icon/health-and-welbeing.png'),
    onlineClasses:require('./eventox/icon/e-learning.png'),
    talkShow:require('./eventox/icon/talk-show-1.png'),


    
    
  
}