import { StylesConfig } from 'react-select';

export const colourStyles: StylesConfig = {
    
    control: (styles) => ({ ...styles, backgroundColor: '#272b30', border: '1px solid #272b30 !important',height:50,boxShadow:'none' }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
            ...styles,
            backgroundColor: '#272b30',
            ':active': {
               
                border:'none'
            }
        }
    },
    input: (styles) => ({ ...styles,color: '#ffff' }),
    placeholder: (styles) => ({ ...styles, color: '#ffff' }),
    singleValue: (styles) => ({ ...styles, color: '#ffffff' }),
    menu:(styles) => ({ ...styles, backgroundColor: '#272b30' }),
}