import React from 'react'
import { EventType } from '../../types/Events';
import Select from 'react-select';
import { colourStyles } from '../../utils/customSelectStyle';
import { eventDuration, eventTime } from '../../utils/constant';
import { SelectOptionType } from '../../types/Common';
import { images } from '../../assets/images';

type EventBasicTabProps = {
    handleChange: (e: any) => void;
    formData: EventType;
    onSelectChange: (val: any, key: string) => void;
    selectedFile: any;
    handleThumbBtnClick: () => void;
    fileInputRef: any;
    handleFileChange: (e: any) => void;
    chooseOnlineEventTypes: (e: any) => void;
    countries: SelectOptionType[];
    categories: SelectOptionType[];


}
const priceOptions = [
    {value: 'free',label: 'Free'},
    {value: 'paid',label: 'Paid'},
]
const eventTypeOptions = [
    {value: 'online',label: 'Online Event'},
    {value: 'offline',label: 'Venue Event'},
]
const onlineEventTypeOptions = [
    {value: 'standard webinar',label: 'Standard Webinar',icon: images.standardWebinar},
    {value: 'training workshop',label: 'Training and Workshop',icon: images.training},
    {value: 'online classes',label: 'Online Classes',icon: images.onlineClasses},
    {value: 'talk show',label: 'Talk Show',icon: images.talkShow},
    // {value: 'other',label: 'Other',icon: images.standardWebinar},

]
const EventBasicTab = (props: EventBasicTabProps) => {
    const { 
        handleChange,
        formData, 
        onSelectChange, 
        selectedFile, 
        handleThumbBtnClick, 
        fileInputRef, 
        handleFileChange, 
        countries, 
        categories ,
        chooseOnlineEventTypes

    } = props;
    return (
        <div className="step-tab-panel step-tab-info active" id="tab_step1">
            <div className="tab-from-content">
                <div className="main-card">
                    <div className="bp-title">
                        <h4><i className="fa-solid fa-circle-info step_icon me-3"></i>Details</h4>
                    </div>
                    <div className="p-4 bp-form main-form">
                        <div className="row">
                            <div className="col-lg-12 col-md-12">
                                <div className="form-group border_bottom pb_30">
                                    <label className="form-label fs-16">Give your event a name.*</label>
                                    <p className="mt-2 d-block fs-14 mb-3">See how your name appears on the event page and a list of all places where your event name will be used.</p>
                                    <input className="form-control h_50" type="text" placeholder="Enter event name here" name='eventName' onChange={handleChange} value={formData.eventName} />
                                </div>
                                <div className="form-group border_bottom pt_30 pb_30">
                                    <label className="form-label fs-16">Choose a category for your event.*</label>
                                    <p className="mt-2 d-block fs-14 mb-3">Choosing relevant categories helps to improve the discoverability of your event. </p>
                                    <Select
                                        className="eventox-select"
                                        options={categories}
                                        onChange={(val: any) => onSelectChange(val, 'categoryId')}
                                        styles={colourStyles}
                                    />

                                </div>
                               
                               
                                <div className="form-group border_bottom pt_30 pb_30">
                                    <label className="form-label fs-16">When is your event?*</label>
                                    <p className="mt-2 fs-14 d-block mb-3">Tell your attendees when your event starts so they can get ready to attend.</p>
                                    <div className="row g-2">
                                        <div className="col-md-6">
                                            <label className="form-label mt-3 fs-6">Event Date.*</label>
                                            <div className="loc-group position-relative">
                                                <input className="form-control h_50 datepicker-here" data-language="en" type="date" name='eventDate' placeholder="YYYY-MM-DD" onChange={handleChange} value={formData.eventDate} />
                                                <span className="absolute-icon"><i className="fa-solid fa-calendar-days"></i></span>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="row g-2">
                                                <div className="col-md-6">
                                                    <div className="clock-icon">
                                                        <label className="form-label mt-3 fs-6">Time</label>
                                                        <Select
                                                            className='eventox-select'
                                                            options={eventTime}
                                                            styles={colourStyles}
                                                            onChange={(val: any) => onSelectChange(val, 'eventTime')}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <label className="form-label mt-3 fs-6">Duration</label>
                                                    <Select
                                                        className='eventox-select'
                                                        options={eventDuration}
                                                        styles={colourStyles}
                                                        onChange={(val: any) => onSelectChange(val, 'eventDuration')}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group pt_30 pb_30">
                                    <label className="form-label fs-16">Add a few images to your event banner.</label>
                                    <p className="mt-2 fs-14 d-block mb-3 pe_right">Upload colorful and vibrant images as the banner for your event! See how beautiful images help your event details page.</p>
                                    <div className="content-holder mt-4">
                                        <div className="default-event-thumb">
                                            <div className="default-event-thumb-btn" onClick={handleThumbBtnClick}>
                                                <div className="thumb-change-btn" >
                                                    <input type="file" id="thumb-img" ref={fileInputRef} onChange={handleFileChange} style={{ display: 'none' }} accept="image/jpeg, image/png" />
                                                    <label style={{ cursor: 'pointer' }}>Change Image</label>
                                                </div>
                                            </div>
                                            {selectedFile && <img src={URL.createObjectURL(selectedFile)} alt="Uploaded" />}                                                                            </div>
                                    </div>
                                </div>
                                <div className="form-group border_bottom pb_30">
                                    <label className="form-label fs-16">Please describe your event.</label>
                                    <p className="mt-2 fs-14 d-block mb-3">Write a few words below to describe your event and provide any extra information such as schedules, itinerary or any special instructions required to attend your event.</p>
                                    <div className="text-editor mt-4">
                                        <textarea className="form-control" name='eventDescription' rows={5} onChange={handleChange} value={formData.eventDescription} />

                                    </div>
                                </div>
                                <div className="form-group border_bottom pt_30 pb_30">
                                    <label className="form-label fs-16">Choose your event ticket type*</label>
                                    <Select
                                        className="eventox-select"
                                        options={priceOptions}
                                        onChange={(val: any) => onSelectChange(val, 'ticketType')}
                                        styles={colourStyles}
                                    />

                                </div>
                                <div className="form-group border_bottom pt_30 pb_30">
                                    <label className="form-label fs-16">Choose your event type*</label>
                                    <Select
                                        className="eventox-select"
                                        options={eventTypeOptions}
                                        onChange={(val: any) => onSelectChange(val, 'eventType')}
                                        styles={colourStyles}
                                    />

                                </div>
                                {formData.eventType===''?<></>:
                                formData.eventType==='online'?
                                <div className="form-group pt_30 pb-2">
                                <label className="form-label fs-16">What type of online event are you hosting?*</label>
                                <p className="mt-2 fs-14 d-block mb-3">Choosing the type of your event helps us to create a more tailored experience for you. <a href="#" className="a-link">Learn more</a></p>
                                <div className="stepper-data-set">
                                    <div className="content-holder template-selector">
                                        <div className="row g-3">
                                            {onlineEventTypeOptions.map((item, index) => (
                                            <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-6" onClick={()=>chooseOnlineEventTypes(item.value)} key={index}>
                                                <div className="template-item mt-3">
                                                    <input id="standard_webinar" type="radio" name="onlineEventType" value={item.value}  checked={formData.onlineEventType===item.value}/>
                                                    <label className="template sw-template">
                                                        <img src={item.icon} alt={item.label}/>
                                                    </label>
                                                    <h6 className="hosting-title fs-14 mt-2 mb-0">{item.label}</h6>
                                                </div>
                                            </div>
                                            ))}
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>:
                                <div className="form-group pt_30 pb-2">
                                    <label className="form-label fs-16">Where is your event taking place? *</label>
                                    <p className="mt-2 fs-14 d-block mb-3">Add a venue to your event to tell your attendees where to join the event.</p>
                                    <div className="stepper-data-set">
                                        <div className="content-holder template-selector">
                                            <div className="row g-4">

                                                <div className="col-md-12">
                                                    <div className="form-group mt-1">
                                                        <label className="form-label fs-6">Venue*</label>
                                                        <input className="form-control h_50" type="text" placeholder="" name='eventVenue' onChange={handleChange} value={formData.eventVenue} />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group mt-1">
                                                        <label className="form-label fs-6">Address line 1*</label>
                                                        <input className="form-control h_50" type="text" placeholder="" name='eventAddress1' onChange={handleChange} value={formData.eventAddress1} />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group mt-1">
                                                        <label className="form-label fs-6">Address line 2*</label>
                                                        <input className="form-control h_50" type="text" placeholder="" name='eventAddress2' onChange={handleChange} value={formData.eventAddress2} />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group main-form mt-1">
                                                        <label className="form-label">Country*</label>
                                                        <Select
                                                            className='eventox-select'
                                                            options={countries}
                                                            styles={colourStyles}
                                                            onChange={(val: any) => onSelectChange(val, 'eventCountry')}
                                                        />

                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group mt-1">
                                                        <label className="form-label">State*</label>
                                                        <input className="form-control h_50" type="text" placeholder="" name='eventState' onChange={handleChange} value={formData.eventState} />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-12">
                                                    <div className="form-group mt-1">
                                                        <label className="form-label">City/Suburb*</label>
                                                        <input className="form-control h_50" type="text" placeholder="" name='eventCity' onChange={handleChange} value={formData.eventCity} />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-12">
                                                    <div className="form-group mt-1">
                                                        <label className="form-label">Zip/Post Code*</label>
                                                        <input className="form-control h_50" type="text" placeholder="" name='eventZip' onChange={handleChange} value={formData.eventZip} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EventBasicTab