import React from 'react'
import NormalTable from '../../components/common/Tables/NormalTable'

type Props = {}

const EventReport = (props: Props) => {
    return (
        <>
            <div className="col-md-12">
                <div className="d-main-title">
                    <h3><i className="fa-solid fa-chart-pie me-3"></i>Reports</h3>
                </div>
            </div>
            <div className="col-md-12">
                <div className="main-card mt-5">
                    <div className="dashboard-wrap-content p-4">
                        <div className="nav custom2-tabs btn-group" role="tablist">
                            <button className="tab-link ms-0 active" data-bs-toggle="tab" data-bs-target="#orders-tab" type="button" role="tab" aria-controls="orders-tab" aria-selected="true">Orders (<span className="total_event_counter">1</span>)</button>
                            <button className="tab-link" data-bs-toggle="tab" data-bs-target="#customers-tab" type="button" role="tab" aria-controls="customers-tab" aria-selected="false">Customers (<span className="total_event_counter">0</span>)</button>
                            <button className="tab-link" data-bs-toggle="tab" data-bs-target="#tickets-tab" type="button" role="tab" aria-controls="tickets-tab" aria-selected="false">Tickets (<span className="total_event_counter">1</span>)</button>
                            <button className="tab-link" data-bs-toggle="tab" data-bs-target="#payouts-tab" type="button" role="tab" aria-controls="payouts-tab" aria-selected="false">Payouts (<span className="total_event_counter">1</span>)</button>
                        </div>
                        <div className="d-md-flex flex-wrap align-items-center">
                            <div className="dashboard-date-wrap mt-4">
                                <div className="form-group">
                                    <div className="relative-input position-relative">
                                        <input className="form-control h_40" type="text" placeholder="Search by name" value="" />
                                        <i className="uil uil-search"></i>
                                    </div>
                                </div>
                            </div>
                            <div className="rs ms-auto mt-4 mt_r4">
                                <button className="pe-4 w-100 ps-4 text-center co-main-btn h_40 d-inline-block"><i className="fa-solid fa-arrow-rotate-right me-3"></i>Refresh</button>
                            </div>
                        </div>
                        <div className="main-form mt-4">
                            <div className="row g-3">
                                <div className="col-lg-3 col-md-6">
                                    <div className="form-group">
                                        <select className='eventox-select'>
                                            <option value="all events" selected>All Events</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6">
                                    <div className="form-group">
                                        <select className="eventox-select">
                                            <option value="Today">Today</option>
                                            <option value="Yesterday">Yesterday</option>
                                            <option value="Last 7 Days">Last 7 Days</option>
                                            <option value="Last 30 Days" selected>Last 30 Days</option>
                                            <option value="This Month">This Month</option>
                                            <option value="Last Month">Last Month</option>
                                            <option value="Custom Range">Custom Range</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6">
                                    <div className="form-group">
                                        <select className="eventox-select">
                                            <option value="All Orders" selected>All Orders</option>
                                            <option value="Refunded">Refunded</option>
                                            <option value="Refund Rejected">Refund Rejected</option>
                                            <option value="Refund Requested">Refund Requested</option>
                                            <option value="Partially Refunded">Partially Refunded</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6">
                                    <div className="form-group">
                                        <select className="eventox-select">
                                            <option value="all">All Orders</option>
                                            <option value="1">Active</option>
                                            <option value="0">Canceled</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="event-list">
                    <NormalTable columns={[]} data={[]} />
                </div>
            </div>
        </>
    )
}

export default EventReport