import React, { useState } from 'react'

type EventListCardProps = {
    eventName: string,
    description: string, 
    bannerImg: string, 
    eventStatus: string, 
    eventDate: string, 
    totalTicket: number, 
    soldTicket: number
}

const EventListCard = (props: EventListCardProps) => {
    const { eventName, description, bannerImg, eventStatus, eventDate, totalTicket, soldTicket } = props;
    const [onActionBtn, setOnActionBtn] = useState(false)
    return (
        <div className="main-card mt-4">
            <div className="contact-list">
                <div className="card-top event-top p-4 align-items-center top d-md-flex flex-wrap justify-content-between">
                    <div className="d-md-flex align-items-center event-top-info">
                        <div className="card-event-img">
                            <img src={bannerImg} alt={eventName} />
                        </div>
                        <div className="card-event-dt">
                            <h5>{eventName}</h5>
                            <p>{description}</p>
                        </div>
                    </div>
                    <div className={`dropdown ${onActionBtn&&'show'}`}>
                        <button className="option-btn" type="button" onClick={()=>setOnActionBtn(!onActionBtn)}><i className="fa-solid fa-ellipsis-vertical"></i></button>
                        <div className={`dropdown-menu dropdown-menu-right ${onActionBtn&&'ticket-dropdown show'}`}>
                            <a href="#" className="dropdown-item"><i className="fa-solid fa-gear me-3"></i>Manage</a>
                            <a href="#" className="dropdown-item"><i className="fa-solid fa-eye me-3"></i>Preview Event</a>
                            <a href="#" className="dropdown-item delete-event"><i className="fa-solid fa-trash-can me-3"></i>Delete</a>
                        </div>
                    </div>
                </div>
                <div className="bottom d-flex flex-wrap justify-content-between align-items-center p-4">
                    <div className="icon-box ">
                        <span className="icon">
                            <i className="fa-solid fa-location-dot"></i>
                        </span>
                        <p>Status</p>
                        <h6 className="coupon-status">{eventStatus}</h6>
                    </div>
                    <div className="icon-box">
                        <span className="icon">
                            <i className="fa-solid fa-calendar-days"></i>
                        </span>
                        <p>Starts on</p>
                        <h6 className="coupon-status">{eventDate}</h6>
                    </div>
                    <div className="icon-box">
                        <span className="icon">
                            <i className="fa-solid fa-ticket"></i>
                        </span>
                        <p>Ticket</p>
                        <h6 className="coupon-status">{totalTicket}</h6>
                    </div>
                    <div className="icon-box">
                        <span className="icon">
                            <i className="fa-solid fa-tag"></i>
                        </span>
                        <p>Tickets sold</p>
                        <h6 className="coupon-status">{soldTicket}</h6>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EventListCard