import apiClient from "./apiClient";


/** Events Section */
/**
 * Fetches all events.
 * @param {number} id - The user ID.
 * @returns {Promise<Object>} - The user object.
 */
export const getAllEvents = async () => {
    const response = await apiClient.get(`/events`);
    return response.data;
};

export const getEventBySlug = async (slug) => {
    const response = await apiClient.get(`/events/${slug}`);
    return response.data;
};


/** Ticket Section */

/**
 * Fetches all tickets by slug.
 * @param {string} slug - The user ID.
 * @returns {Promise<Object>} - The user object.
 */

export const getAllTicketsBySlug = async (slug) => {
    const response = await apiClient.get(`/ticket-type/${slug}`);
    return response.data;
};


/** Booking  Section */

export const createBooking = async (data) => {
    const response = await apiClient.post('/booking', data);
    return response.data;
};

export const getBookingPayment = async (bookingNo) => {
    const response = await apiClient.get(`/booking/payment/${bookingNo}`);
    return response.data;
}

export const getBookingDetails = async (bookingNo) => {
    const response = await apiClient.get(`/booking/${bookingNo}`);
    return response.data;
}

export const sendEmailConfirmation = async (bookingNo) => {
    const response = await apiClient.get(`/booking/send-confirmation/${bookingNo}`);
    return response.data;
}

export const updateBookingConfirmation = async (bookingId,data) => {
    const response = await apiClient.put(`/booking/${bookingId}`,data);
    return response.data;
}



//Admin API

export const getAllEventByOrganizer = async () => {
    const response = await apiClient.get(`/events/organizer`);
    return response.data;
}

export const getAllBookingByEvent = async (slugId) => {
    const response = await apiClient.get(`/booking/event/${slugId}`);
    return response.data;
}
export const getAllBookingByOrganizer = async () => {
    const response = await apiClient.get(`/booking/organizer`);
    return response.data;
}

//Auth
export const userLogin = async (data) => {
    
    const response = await apiClient.post('/auth/login', data);
    return response.data;
   
};
//get organizer profile
export const getOrganizerProfile = async () => {
    const response = await apiClient.get('/organizer/profile');
    return response.data;
};
// Get all categories
export const getAllCategories = async () => {
    const response = await apiClient.get('/categories');
    return response.data;
};

// Get all countries
export const getAllCountries = async () => {
    const response = await apiClient.get('/countries');
    return response.data;
};
//get all organizer events
export const getAllOrganizerEvents = async () => {
    const response = await apiClient.get('/events/organizer');
    return response.data;
};
// create event api 
export const createOrganizerEvent = async (data) => {
    const response = await apiClient.post('/events/organizer', data);
    return response.data;
};
//update event api
export const updateEvent = async (id,data) => {
    const response = await apiClient.put(`/events/${id}`, data);
    return response.data;
};
//get all ticket by slug 
export const getAllTicketBySlug = async (slug) => {
    const response = await apiClient.get(`/ticket-type/${slug}`);
    return response.data;
};
//create ticket api
export const createTicket = async (data) => {
    const response = await apiClient.post('/ticket-type', data);
    return response.data;
};
//update ticket api'
export const updateTicket = async (id,data) => {
    const response = await apiClient.put(`/ticket-type/${id}`, data);
    return response.data;
};