
import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import LoginPage from '../pages/Login/LoginPage';
import RegisterPage from '../pages/Register/RegisterPage';
import HomePage from '../pages/Home/HomePage'; // Assuming you have a HomePage component
import PrivateRoute from './PrivateRoute'; // We will create this component
import Events from '../pages/Events/Events';
import EventReport from '../pages/Events/EventReport';
import ChooseEventType from '../pages/Events/ChooseEventType';
import CreateVenueEvent from '../pages/Events/CreateEvent';
import CreateEvent from '../pages/Events/CreateEvent';
import EditEvent from '../pages/Events/EditEvent';

const AppRoutes = () => {
  const isAuth = !!localStorage.getItem('token');
  return (
    <Router>
      <Routes>
       
        <Route path="/login" element={<LoginPage />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route path="/home" element={<PrivateRoute><HomePage /></PrivateRoute>} />
        <Route path="/events" element={<PrivateRoute><Events /></PrivateRoute>} />
        <Route path="/reports" element={<PrivateRoute><EventReport /></PrivateRoute>} />
        <Route path="/choose-event" element={<PrivateRoute><ChooseEventType /></PrivateRoute>} />
        <Route path="/create-event" element={<PrivateRoute><CreateVenueEvent /></PrivateRoute>} />
        <Route path="/edit-venue-event/:slugId" element={<PrivateRoute><EditEvent /></PrivateRoute>} />
        <Route path="/create-online-event" element={<PrivateRoute><CreateEvent /></PrivateRoute>} />
        <Route path="/create-event" element={<PrivateRoute><HomePage /></PrivateRoute>} />
        <Route path="/profile" element={<PrivateRoute><HomePage /></PrivateRoute>} />
        <Route path="/" element={<Navigate to={isAuth ? '/home' : '/login'} />} />
        
       

      </Routes>
    </Router>
  )
};

export default AppRoutes;