import React, { useState } from 'react'
import Header from './Header'
import Footer from './Footer'

type LayoutProps = {
    children: React.ReactNode
}

const Layout = (props: LayoutProps) => {
    const { children } = props
    const [onMinifyBody, setOnMinifyBody] = useState(false)
    const handleMinifyBody = () => {
        setOnMinifyBody(!onMinifyBody)
    };
    return (
        <>
            <Header onChangeMenu={handleMinifyBody} />
            <div className={`wrapper wrapper-body ${onMinifyBody ? 'wrapper__minify' : ''}`}>
                <div className="dashboard-body">
                    <div className="container-fluid">
                        {children}
                    </div>
                </div>
            </div>
            <Footer />
        </>

    )
}

export default Layout