import React from 'react'

type Props = {}

const Footer = (props: Props) => {
  return (
    <div>Footer</div>
  )
}

export default Footer