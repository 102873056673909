import React, { useState } from 'react'
import TicketCard from '../../components/common/Ticket/TicketCard'
import { images } from '../../assets/images'
import { EventType, TicketsType } from '../../types/Events'

type TicketTabProps = {
    tickets: TicketsType[];
    setTicketModalOpen: (value: boolean) => void;
    onEditTicket: (ticket: TicketsType) => void;
    formData: EventType
}

const TicketTab = (props: TicketTabProps) => {
    const { tickets, setTicketModalOpen, onEditTicket, formData } = props;
    const [isOrganizerPlatform, setIsOrganizerPlatform] = useState(false)
    return (
        <div className="step-tab-panel step-tab-gallery">
            <div className="tab-from-content">
                <div className="main-card">
                    <div className="bp-title">
                        <h4><i className="fa-solid fa-ticket step_icon me-3"></i>Tickets</h4>
                    </div>
                    <div className="bp-form main-form">
                        <div className="p-4 form-group border_bottom pb_30">
                            {formData.ticketType === 'free' ?
                                <div className="">
                                    <div className="ticket-section">
                                        <label className="form-label fs-16">You Have Selected a Free Event!</label>
                                        <p className="mt-2 fs-14 d-block mb-3 pe_right">Since you've selected a free event, no payment is required. Enjoy the event at no cost! Please click next to complete the registration</p>
                                    </div>
                                </div>
                                :
                                <>
                                    <div className='border_bottom'>
                                        <div className="d-flex align-items-start pb_30">
                                            <label className="btn-switch m-0 me-3">
                                                <input type="checkbox" className="" id="booking-end-time-btn" checked={isOrganizerPlatform} onChange={() => setIsOrganizerPlatform(!isOrganizerPlatform)} />
                                                <span className="checkbox-slider"></span>
                                            </label>
                                            <div className="d-flex flex-column">
                                                <label className="color-black fw-bold mb-1">I want the bookings from my platform.</label>
                                                <p className="mt-2 fs-14 d-block mb-0">Choose between our seamless integrated payment solution or use your own trusted payment gateway to manage bookings effortlessly.</p>
                                            </div>

                                        </div>
                                        {isOrganizerPlatform ?
                                            <div className="row g-3 p">
                                                <div className="col-md-12">
                                                    <label className="form-label mt-3 fs-6">Enter your url here</label>
                                                    <div className="loc-group position-relative">
                                                        <input className="form-control h_50 datepicker-here" type='text' value="" />
                                                    </div>
                                                </div>

                                            </div> :
                                            <div className="">
                                                <div className="ticket-section">
                                                    <label className="form-label fs-16">Let's create tickets!</label>
                                                    <p className="mt-2 fs-14 d-block mb-3 pe_right">Create tickets for your event by clicking on the 'Add Tickets' button below.</p>
                                                </div>

                                             
                                                    <>
                                                        <div className="d-flex align-items-center justify-content-between pt-4 pb-3 full-width">
                                                            <h3 className="fs-18 mb-0">Tickets (<span className="venue-event-ticket-counter">{tickets.length}</span>)</h3>
                                                            <div className="dropdown dropdown-default dropdown-normal btn-ticket-type-top">
                                                                <button className=" main-btn btn-hover h_40 pe-4 ps-4" type="button" onClick={() => setTicketModalOpen(true)}>
                                                                    <span>Add Tickets</span>
                                                                </button>

                                                            </div>
                                                        </div>

                                                        {tickets.length <= 0 &&
                                                            <div className="ticket-type-item-empty text-center p_30">
                                                                <div className="ticket-list-icon d-inline-block">
                                                                    <img src={images.ticket} alt="" />
                                                                </div>
                                                                <h4 className="color-black mt-4 mb-3 fs-18">You have no tickets yet.</h4>
                                                                <p className="mb-0">You have not created a ticket yet. Please click the button above to create your event ticket.</p>
                                                            </div>
                                                        }
                                                        <div className="ticket-type-item-list mt-4">
                                                            {tickets.map((ticket: any) => (
                                                                <TicketCard tickets={ticket} onEditTicket={onEditTicket} />
                                                            ))}
                                                        </div>
                                                    </>
                                                
                                            </div>
                                        }
                                    </div>


                                </>

                            }

                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default TicketTab