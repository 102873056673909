import React from 'react'
import { images } from '../../../assets/images'

type Props = {}

const EventNotFound = (props: Props) => {
  return (
    <div className="main-card mt-4">
    <div className="d-flex align-items-center justify-content-center flex-column min-height-430">
        <div className="event-list-icon">
            <img src={images.calendar} alt="" />
        </div>
        <p className="font-16 mt-4 text-light3">No Event found</p>
    </div>
</div>
  )
}

export default EventNotFound