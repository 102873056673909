import React from 'react'

type DashboardWidgetProps = {
    mainTitle:string,
    subTitle:string,
    description:string,
    cardClass:string,
    icon:string
}

const DashboardWidget = (props: DashboardWidgetProps) => {
    const {mainTitle,subTitle,description,cardClass,icon}=props;
  return (
    <div className={`dashboard-report-card ${cardClass}`}>
    <div className="card-content">
        <div className="card-content">
            <span className="card-title fs-6">{mainTitle}</span>
            <span className="card-sub-title fs-3">{subTitle}</span>
            <div className="d-flex align-items-center">
                <span><i className="fa-solid fa-arrow-trend-up"></i></span>
                <span className="text-Light font-12 ms-2 me-2">0.00%</span>
                <span className="font-12 color-body text-nowrap">{description}</span>
            </div>
        </div>
        <div className="card-media">
            <i className={`fa-solid ${icon}`}></i>
        </div>
    </div>
</div>
  )
}

export default DashboardWidget